import { getLocalTimezone } from '@cf/core';
import { TimezoneEnum } from '../../account';

import { DayEnum } from '@cf/shared/ui/typography';

export const firstMonday = [
  DayEnum.Monday,
  DayEnum.Tuesday,
  DayEnum.Wednesday,
  DayEnum.Thursday,
  DayEnum.Friday,
  DayEnum.Saturday,
  DayEnum.Sunday,
];

export const firstSunday = [
  DayEnum.Sunday,
  DayEnum.Monday,
  DayEnum.Tuesday,
  DayEnum.Wednesday,
  DayEnum.Thursday,
  DayEnum.Friday,
  DayEnum.Saturday,
];

export const firstSaturday = [
  DayEnum.Saturday,
  DayEnum.Sunday,
  DayEnum.Monday,
  DayEnum.Tuesday,
  DayEnum.Wednesday,
  DayEnum.Thursday,
  DayEnum.Friday,
];

export const TIME_CONFIG = {
  timeFormat: 12,
  defaultStartTime: '09:00 AM',
  defaultEndTime: '05:00 PM',
  weeks: firstSunday,
  timezone: getLocalTimezone() as TimezoneEnum,
};
